.modal {
  background-color: white;
  width: 28rem;
  height: 20rem; }
  .modal-button {
    background-color: #0606bc;
    color: white;
    text-decoration: none;
    border: none; }

@media (max-width: 768px) {
  .displayhelper {
    display: block; }
  .courtyard {
    background-color: #130f4d;
    width: 100%;
    height: 100%;
    color: white;
    overflow: auto;
    font-family: "Playfair Display", serif;
    text-align: center; }
    .courtyard-center {
      margin-left: auto;
      margin-right: auto;
      width: fit-content;
      margin-top: 2.5rem; }
  .blockOne {
    display: inline-block;
    width: fit-content; }
  .blockTwo {
    display: inline-block;
    width: fit-content;
    vertical-align: top;
    position: relative; }
  .dividerhr {
    border: #ffb920 solid 0.065rem; }
  .title {
    display: block;
    width: fit-content;
    height: 2rem;
    margin-left: auto;
    margin-right: auto;
    border-bottom: white solid 0.1rem;
    font-size: 1rem;
    text-align: center;
    word-spacing: 1rem;
    letter-spacing: 0.4rem;
    font-weight: 700; }
  .info {
    display: inline-block;
    width: fit-content;
    margin-top: 3rem;
    margin-right: auto;
    margin-left: auto;
    width: 23rem; }
    .info-controls {
      margin-right: auto;
      margin-left: auto;
      width: fit-content; }
    .info-portrait {
      display: block;
      height: 28rem;
      width: 22rem;
      border: #ffb920 solid 0.1rem;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 1rem; }
    .info-photo {
      width: 19rem;
      margin-top: 1.8rem; }
    .info-contact {
      display: inline-block;
      height: 4.5rem;
      margin-top: 0.8rem; }
      .info-contact-threads {
        display: inline-block;
        color: white;
        font-size: 2.1rem;
        width: fit-content;
        padding-top: 1.3rem;
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
      .info-contact-twitter {
        display: inline-block;
        color: white;
        font-size: 2.1rem;
        width: fit-content;
        padding-top: 1.3rem;
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .info-quote {
      vertical-align: top;
      display: inline-block;
      width: 12rem;
      border: white solid 0.1rem;
      margin-top: 0.8rem;
      padding: 1.3rem;
      text-align: center;
      color: white;
      text-decoration: none;
      font-size: 1.8rem;
      justify-content: center;
      align-items: center;
      float: right;
      margin-right: 0.8rem; }
  .affiliates {
    margin-top: 1.5rem;
    display: inline-block;
    width: 20rem;
    border: white solid 0.1rem;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 1.8rem;
    padding-top: 1.8rem;
    columns: 2 6rem;
    margin-bottom: 6.5rem;
    position: relative; }
    .affiliates-logoBox {
      vertical-align: top;
      display: inline-block;
      min-width: 4rem;
      max-height: 2rem;
      background-color: white;
      padding: 0.7rem;
      margin: 0.7rem; }
    .affiliates-logo {
      width: 5rem;
      max-height: 6rem; }
  .chapel {
    background-color: #130f4d;
    width: 100%;
    height: 100%;
    color: white;
    font-family: "Playfair Display", serif;
    overflow: auto;
    text-align: center; }
    .chapel-center {
      margin-left: auto;
      margin-right: auto;
      width: fit-content;
      margin-top: 2.5rem; }
    .chapel-details {
      display: block; }
    .chapel-title {
      margin-left: auto;
      margin-right: auto;
      width: fit-content;
      font-size: 2rem; }
  .details {
    display: inline-block;
    width: 20rem;
    height: fit-content;
    margin: 1rem;
    font-size: 2.1rem;
    font-family: "Roboto", sans-serif;
    font-weight: 100; }
    .details-portraitcontainer {
      margin-left: auto;
      margin-right: auto;
      width: fit-content; }
    .details-painting {
      width: 17rem;
      margin-left: auto;
      margin-right: auto; }
  .form {
    display: inline-block;
    vertical-align: top;
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.5rem;
    color: darkblue;
    font-family: "Roboto", sans-serif;
    margin-bottom: 2.5rem; }
    .form-submitBttn {
      background-color: #332c9c;
      color: white;
      border-radius: 1rem;
      padding: 1rem;
      border: none;
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-top: 2rem; }
    .form-blur {
      filter: blur(3.8px);
      pointer-events: none; }
    .form-thankyou {
      color: #130f4d;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      top: 15rem;
      position: absolute;
      z-index: 10;
      background-color: white;
      border-radius: 1rem;
      border: 0.1rem silver solid;
      width: 10rem;
      padding: 2rem;
      font-size: 2rem; }
    .form-structure {
      width: 17rem;
      height: fit-content;
      background-color: rgba(255, 255, 255, 0.817);
      margin-left: auto;
      margin-right: auto;
      padding: 3rem;
      border-radius: 0.1rem; }
    .form-border {
      background: #ffb920;
      text-align: center;
      height: 0.1rem;
      position: relative;
      border: 0;
      margin-bottom: 4rem; }
    .form-border:after {
      content: "";
      position: absolute;
      border: 0;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-image: linear-gradient(to right, #130f4d, rgba(255, 184, 32, 0) 50px), linear-gradient(to left, #130f4d, rgba(255, 184, 32, 0) 50px); }
  .states {
    display: block;
    border: white 0.1rem solid;
    padding: 1.3rem;
    margin-top: 2rem;
    font-size: 1rem;
    text-align: center; }
    .states-list {
      margin-right: 0.4rem;
      margin-left: 0.4rem;
      font-size: 2.4rem;
      display: inline-block;
      list-style-type: circle;
      font-family: "Italianno", cursive; } }

@media (min-width: 769px) {
  .courtyard {
    background-color: #130f4d;
    width: 100%;
    height: 100vh;
    color: white;
    overflow: auto;
    font-family: "Playfair Display", serif; }
    .courtyard-center {
      margin-left: auto;
      margin-right: auto;
      width: fit-content;
      margin-top: 2.5rem; }
  .blockOne {
    display: inline-block;
    width: fit-content; }
  .blockTwo {
    display: inline-block;
    width: fit-content;
    vertical-align: top;
    position: relative; }
  .dividerhr {
    border: #ffb920 solid 0.065rem; }
  .title {
    display: block;
    width: 40rem;
    height: 4rem;
    margin-left: auto;
    margin-right: auto;
    border-bottom: white solid 0.1rem;
    font-size: 2rem;
    text-align: center;
    word-spacing: 2rem;
    letter-spacing: 0.4rem; }
  .states {
    display: block;
    border: white 0.1rem solid;
    padding: 1.3rem;
    margin-top: 2rem;
    font-size: 1rem;
    text-align: center;
    margin-bottom: 5.5rem; }
    .states-list {
      margin-right: 0.4rem;
      margin-left: 0.4rem;
      font-size: 2.4rem;
      display: inline-block;
      list-style-type: circle;
      font-family: "Italianno", cursive; }
  .info {
    display: inline-block;
    width: fit-content;
    height: fit-content;
    margin-top: 3rem;
    margin-right: 1.5rem;
    width: 23rem; }
    .info-controls {
      margin-right: auto;
      margin-left: auto;
      width: fit-content; }
    .info-portrait {
      display: block;
      height: 28rem;
      width: 22rem;
      border: #ffb920 solid 0.1rem;
      text-align: center;
      margin-right: auto;
      margin-left: auto; }
    .info-photo {
      width: 19rem;
      margin-top: 1.8rem; }
    .info-contact {
      display: inline-block;
      height: 4.5rem;
      margin-top: 0.8rem; }
      .info-contact-threads {
        display: inline-block;
        color: white;
        font-size: 2.1rem;
        width: fit-content;
        padding-top: 1.3rem;
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
      .info-contact-twitter {
        display: inline-block;
        color: white;
        font-size: 2.1rem;
        width: fit-content;
        padding-top: 1.3rem;
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .info-quote {
      vertical-align: top;
      display: inline-block;
      height: fit-content;
      width: 12rem;
      border: white solid 0.1rem;
      margin-top: 0.8rem;
      padding: 1.3rem;
      text-align: center;
      color: white;
      text-decoration: none;
      font-size: 1.8rem;
      justify-content: center;
      align-items: center;
      float: right;
      margin-right: 0.8rem; }
  .affiliates {
    margin-top: 5rem;
    vertical-align: top;
    display: inline-block;
    width: 45rem;
    height: fit-content;
    border: white solid 0.1rem;
    text-align: center;
    margin-left: 1.5rem;
    padding-bottom: 1.8rem;
    padding-top: 1.8rem;
    columns: 2 20rem;
    margin-bottom: 6.5rem; }
    .affiliates-logoBox {
      vertical-align: top;
      display: inline-block;
      min-width: 17rem;
      max-height: 6rem;
      background-color: white;
      padding: 0.7rem;
      margin: 1rem; }
    .affiliates-logo {
      width: 17rem;
      max-height: 6rem; }
  .chapel {
    background-color: #130f4d;
    width: 100%;
    height: 100vh;
    color: white;
    font-family: "Playfair Display", serif;
    overflow: auto; }
    .chapel-center {
      margin-left: auto;
      margin-right: auto;
      width: fit-content;
      margin-top: 2.5rem; }
    .chapel-details {
      display: block; }
    .chapel-title {
      margin-left: auto;
      margin-right: auto;
      width: fit-content;
      font-size: 2rem; }
  .details {
    display: inline-block;
    width: 20rem;
    height: 35rem;
    margin: 1rem;
    font-size: 2.1rem;
    font-family: "Roboto", sans-serif;
    font-weight: 100; }
    .details-portraitcontainer {
      margin-left: auto;
      margin-right: auto;
      width: fit-content; }
    .details-painting {
      width: 17rem;
      height: fit-content;
      margin-left: auto;
      margin-right: auto; }
  .form {
    display: inline-block;
    vertical-align: top;
    width: 43rem;
    height: fit-content;
    margin-left: 1rem;
    margin-top: 1.5rem;
    color: darkblue;
    font-family: "Roboto", sans-serif;
    margin-bottom: 2.5rem; }
    .form-blur {
      filter: blur(3.8px);
      pointer-events: none; }
    .form-thankyou {
      color: #130f4d;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      top: 15rem;
      position: absolute;
      z-index: 10;
      background-color: white;
      border-radius: 1rem;
      border: 0.1rem silver solid;
      width: 10rem;
      padding: 2rem;
      font-size: 2rem; }
    .form-submitBttn {
      background-color: #332c9c;
      color: white;
      border-radius: 1rem;
      padding: 1rem;
      border: none;
      display: block;
      margin-left: 1rem;
      margin-top: 2rem; }
    .form-structure {
      width: 33rem;
      height: fit-content;
      background-color: rgba(255, 255, 255, 0.817);
      margin-left: auto;
      margin-right: auto;
      padding: 3rem;
      border-radius: 0.1rem; }
    .form-info1 {
      display: inline-block;
      margin-right: 1rem; }
    .form-border {
      background: #ffb920;
      text-align: center;
      height: 0.1rem;
      position: relative;
      border: 0;
      margin-bottom: 4rem; }
    .form-border:after {
      content: "";
      position: absolute;
      border: 0;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-image: linear-gradient(to right, #130f4d, rgba(255, 184, 32, 0) 50px), linear-gradient(to left, #130f4d, rgba(255, 184, 32, 0) 50px); }
  .displayhelper {
    display: inline-block;
    vertical-align: top; } }
